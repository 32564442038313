import { Types } from './Labeling/Types';
const ToothTypes = {
  REGULAR: Types['Regular'],
  PRIMARY_TOOTH: Types['Primary Tooth'],
  GEMINATED: Types['Geminated'],
  MISSING_TOOTH: Types['Missing Tooth'],
  CROWN: Types['Crown'],
  BRIDGE: Types['Bridge'],
  INLAY_ONLAY_OVERLAY: Types['Inlay / Onlay / Overlay'],
  IMPLANT_ABUTMENT: Types['Implant Abutment'],
  SCAN_BODY: Types['Scan Body'],
  VENEER: Types['Veneer'],
  PREPPED_TOOTH: Types['Prepped Tooth'],
  HEALING_ABUTMENT: Types['Healing Abutment'],
  IRREGULAR_POSITION: Types['Irregular Position'],
  STRUCTURE_LOSS: Types['Structure Loss'],
  SIGNIFICANT_FILLING: Types['Significant Filling'],
  CARIES: Types['Caries'],
  ADD_ON: Types['Add-On'],
};

const typesHelper = {
  typeOnly: [
    ToothTypes.REGULAR,
    ToothTypes.PRIMARY_TOOTH,
    ToothTypes.GEMINATED,
    ToothTypes.MISSING_TOOTH,
    ToothTypes.SCAN_BODY,
    ToothTypes.VENEER,
    ToothTypes.HEALING_ABUTMENT,
  ],
  typeSubTypeMaterial: [
    ToothTypes.CROWN,
    ToothTypes.BRIDGE,
    ToothTypes.TEMPORARY_CROWN,
    ToothTypes.TEMPORARY_BRIDGE,
    ToothTypes.CARIES,
    ToothTypes.ADD_ON,
  ],
  typeSubType: [
    ToothTypes.IMPLANT_ABUTMENT,
    ToothTypes.IRREGULAR_POSITION,
    ToothTypes.STRUCTURE_LOSS,
  ],
  typeMaterial: [
    ToothTypes.PREPPED_TOOTH,
    ToothTypes.INLAY_ONLAY_OVERLAY,
    ToothTypes.SIGNIFICANT_FILLING,
  ],
};

export default typesHelper;
