import React from 'react';
import html2canvas from 'html2canvas';
import BugWindow from './BugWindow';
import { useGetTaskListLogic } from '../../hooks';
import { getUserName } from '../TasksList/TasksList.logic';
const BugReportByUser = ({
  openWindow,
  setOpenWindow,
  taskShortNickName = 'Home Page',
}) => {
  const taskLogic = useGetTaskListLogic();
  const userName = getUserName();

  const handleSendEmail = async (bugDescription) => {
    try {
      const screenshot = await html2canvas(document.body);

      const screenshotDataUrl = screenshot.toDataURL('image/png');
      taskLogic.sendBugReportByUser(
        userName,
        bugDescription,
        screenshotDataUrl,
        taskShortNickName
      );
    } catch (error) {
      console.error('Error sending email:', error);
    }
  };

  return (
    <div>
      {openWindow && (
        <BugWindow
          handleSendEmail={handleSendEmail}
          setOpenWindow={setOpenWindow}
        />
      )}
    </div>
  );
};

export default BugReportByUser;
