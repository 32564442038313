import React, { useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './GroupedDropDownList.module.css';

const GroupedDropDownList = (props) => {
  const {
    disabled,
    items,
    label,
    onChange,
    width,
    selectedValue,
    innerRef,
    className,
    noSelectionText = null,
    disabledSelection = null,
  } = props;

  const inputRef = useRef();

  const classNameGroupedDrop = classNames(styles.selectCss, className);

  useImperativeHandle(innerRef, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  const generateOptions = (meta) =>
    meta.content &&
    meta.content.map((item) => (
      <option
        key={item}
        className={styles.option}
        value={item}
        disabled={item === disabledSelection}
      >
        {item}
      </option>
    ));

  const handleChange = (event) => {
    const selected = event.target.value;
    onChange(selected);
  };

  return (
    <div style={{ width, display: 'flex' }}>
      {label && <label className={styles.label}>{label}:</label>}
      <select
        ref={inputRef}
        value={selectedValue}
        className={classNameGroupedDrop}
        disabled={disabled}
        onChange={(event) => handleChange(event)}
      >
        {noSelectionText && <option value="">{noSelectionText}</option>}
        {items.map((meta) =>
          items.length > 1 ? (
            <optgroup label={meta.label}>{generateOptions(meta)}</optgroup>
          ) : (
            generateOptions(meta)
          )
        )}
      </select>
    </div>
  );
};

GroupedDropDownList.propTypes = {
  /**
   * width of the dropdown list
   */
  width: PropTypes.number,
  /**
   * When `true`, button is disabled
   */
  disabled: PropTypes.bool,
  /**
   * Items is the content of the select
   */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  /**
   * Change Event
   */
  onChange: PropTypes.func.isRequired,
  /**
   * label of the drop down list
   */
  label: PropTypes.string,
};

GroupedDropDownList.defaultProps = {
  items: [],
  label: '',
  selectedValue: null,
};

export default GroupedDropDownList;
