import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUsersList } from '../../../../redux/tasks/tasksSlice';
import { NotificationManager } from 'react-notifications';
import { isNullOrWhitespace, isValidEmail } from '../SharedLogic';
import { SubmitHiddenButton } from '../../Components';
import { FormContent } from './FormContent';
import TabForm from '../TabForm';
import styles from './AddOrEditUser.module.css';

const AddOrEditUser = (props) => {
  const {
    tasksListLogic,
    email,
    setEmail,
    setEmailError,
    setGroupList,
    name,
    setName,
    group,
    setGroup,
    submitRef,
    selectedUser,
    setSelectedUser,
    isActive,
    setIsActive,
    isAdmin,
    setIsAdmin,
    isEdit,
    fetchData,
  } = props;
  const usersList = useSelector(selectUsersList);

  useEffect(() => {
    setGroupList([...new Set(usersList.map((item) => item?.Group))].sort());
  }, [setGroupList, usersList]);

  //  Add User
  const ADD_USER_SECURITY_GROUP_URL =
    'https://portal.azure.com/#blade/Microsoft_AAD_IAM/GroupDetailsMenuBlade/Members/groupId/49a843a5-4ca8-41c4-946a-10a4dd70adcf';

  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    setEmailError(!(e.target.validity.valid && isValidEmail(emailValue)));
  };

  //  Edit User

  const selectEmail = (email) => {
    const user = usersList.find((user) => user.UserId === email);
    if (user) {
      setSelectedUser(user);
      setEmail(email);
      setIsActive(user.IsActive);
      setIsAdmin(user.IsAdmin);
      setName(user.Name);
      setGroup(user.Group);
    } else {
      resetParameters();
    }
  };

  //  Common

  const resetParameters = () => {
    setEmail('');
    setName('');
    setGroup('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate Email
    if (!isEdit) {
      if (!isValidEmail(email)) {
        NotificationManager.error(
          'Please enter a valid email address.',
          'Warning'
        );
        return;
      }

      if (
        usersList.some(
          (item) => item.UserId.toLowerCase() === email.toLowerCase()
        )
      ) {
        NotificationManager.error('Email already exists.', 'Warning');
        return;
      }
    }

    // Validate Name
    if (
      (!isEdit || selectedUser.Name.toLowerCase() !== name.toLowerCase()) && // check if not edit mode or name has been changed in edit mode
      usersList.some((item) => item.Name.toLowerCase() === name.toLowerCase()) // check if the name already exists
    ) {
      NotificationManager.error('Name already exists.', 'Warning');
      return;
    }

    // Validate Group
    if (isNullOrWhitespace(group)) {
      NotificationManager.error('User must be in group.', 'Warning');
      return;
    }

    const user = {
      email,
      name,
      group,
      isActive,
      isAdmin,
      isEdit,
    };

    try {
      await tasksListLogic.postAddOrEditUser(user);
      await fetchData();
      resetParameters();

      NotificationManager.success(
        `User ${isEdit ? 'edited' : 'added'} successfully`,
        'Success!'
      );
    } catch (error) {
      console.error(`Error ${isEdit ? 'editing' : 'adding'} user:`, error);
      NotificationManager.error(
        `An error occurred while ${
          isEdit ? 'editing' : 'adding'
        } the user. Please try again later.`,
        'Warning'
      );
    }
  };

  const additionalProps = {
    ...props,
    securityGroupUrl: ADD_USER_SECURITY_GROUP_URL,
    usersList,
    handleEmailChange,
    selectEmail,
  };

  return (
    <TabForm className={styles.tabForm} onSubmit={handleSubmit}>
      <FormContent {...additionalProps} />
      <SubmitHiddenButton type="submit" ref={submitRef} />
    </TabForm>
  );
};

export const AddUser = (props) => {
  return <AddOrEditUser {...props} />;
};

export const EditUser = (props) => {
  return <AddOrEditUser {...props} isEdit={true} />;
};
