class CircularFixedSizeUniqueQueue {
  constructor(size) {
    this.capacity = size + 1;
    this.elements = new Map();
    this.order = [];
  }

  enqueue(element) {
    const key = this._getKey(element);

    if (this.elements.has(key)) {
      this._removeElement(key);
    }

    this.elements.set(key, element);
    this.order.push(key);

    if (this.elements.size >= this.capacity) {
      const oldestKey = this.order.shift();
      this.elements.delete(oldestKey);
    }
  }

  getList() {
    return this.order.map((key) => this.elements.get(key));
  }

  _getKey(element) {
    return typeof element === 'object' && element !== null
      ? JSON.stringify(element)
      : element;
  }

  _removeElement(key) {
    this.order = this.order.filter((existingKey) => existingKey !== key);
    this.elements.delete(key);
  }
}

export default CircularFixedSizeUniqueQueue;
