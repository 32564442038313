import React, { useRef } from 'react';
import Labeling from './Labeling';
import ToothLabeling from '../ToothLabeling';
import {
  detailSelector,
  findingSelector,
  materialSelector,
  probabilitySelector,
  setCurrentContouring,
  setMaterial,
  setProbability,
  setSubType,
  setType,
  subTypeSelector,
  typeSelector,
  currentContouringSelector,
  currentPhotoIndexSelector,
  currentTypeSelector,
  setDetail,
  setFinding,
} from '../../../redux/marks/currentMarkingSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  labelingDisabledSelector,
  rowSelectedSelector,
  setMarkEdited,
} from '../../../redux/labelingTool/labelingToolSlice';
import { isTooth } from '../../../shared-logic/taskLevelsTypesHelper';
import {
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import {
  MISSING_TOOTH,
  NOT_SELECTED_DETAIL,
  NOT_SELECTED_STR,
} from '../../../shared-logic/params';
import {
  createDetailData,
  createFindingData,
} from '../../../shared-logic/dataStructures';
import {
  useDeletePrevTierMark,
  useSetRecentlyUsedDetails,
} from '../customHooks';
import { selectCampaign, selectConfig } from '../../../redux/tasks/tasksSlice';
import { findingsList } from '../../../config/configUtil';
import { prevTierStateSelector } from '../../../redux/taskStateImages/outputSlice';
import { checkIsMarkNew } from '../shared-logic';
import { isTier3 } from '../../../shared-logic/tiersHelpers';
import useFindingsDetails from './Labeling/FindingsDetails';

const MarkLabelingContainer = () => {
  const dispatch = useDispatch();
  const { findingsId } = useSelector(findingSelector);
  const { detailsId } = useSelector(detailSelector);
  const probability = useSelector(probabilitySelector);
  const type = useSelector(typeSelector);
  const subType = useSelector(subTypeSelector);
  const material = useSelector(materialSelector);
  const campaign = useSelector(selectCampaign);
  const config = useSelector(selectConfig);
  const tier = useSelector(tierSelector);
  const currentContouring = useSelector(currentContouringSelector);
  const prevTierState = useSelector(prevTierStateSelector);

  const rowSelected = useSelector(rowSelectedSelector);
  const labelingDisabled = useSelector(labelingDisabledSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const currentPhotoIndex = useSelector(currentPhotoIndexSelector);
  const currentType = useSelector(currentTypeSelector);
  const { findingsDetails } = useFindingsDetails();

  const detailsRef = useRef(null);
  const subTypeRef = useRef(null);
  const materialRef = useRef(null);

  const isToothLevel = isTooth(taskLevel);
  const deletePrevMark = useDeletePrevTierMark();
  const setRecentlyUsedDetails = useSetRecentlyUsedDetails();

  const changeDropDownSelectedValue = (type, payload) => {
    if (rowSelected) {
      dispatch(setMarkEdited(true));
    }
    switch (type) {
      case 'findings':
        const findingName = findingsDetails.find(
          (f) => f.FindingId === payload
        ).FindingName;
        dispatch(setFinding(createFindingData(payload, findingName)));
        dispatch(setDetail(NOT_SELECTED_DETAIL));
        detailsRef.current.focus();
        break;
      case 'details': {
        const finding = findingsDetails.find((f) => f.FindingId === findingsId);
        const details = finding.Details;
        const detailsName = details.find((d) => d.id === payload).name;
        dispatch(setDetail(createDetailData(payload, detailsName)));
        const data = {
          FindingId: findingsId,
          FindingName: finding.FindingName,
          DetailId: payload,
          DetailName: detailsName,
        };
        setRecentlyUsedDetails(data);

        if (isTier3(tier)) {
          dispatch(setMarkEdited(false));
          const isNewMark = checkIsMarkNew(
            currentContouring,
            currentPhotoIndex,
            currentType,
            prevTierState
          );
          if (isNewMark) {
            dispatch(setMarkEdited(true));
          } else {
            deletePrevMark();
          }
        }
        break;
      }
      case 'probability':
        dispatch(setProbability(payload));
        break;
      case 'type':
        dispatch(setType(payload));
        dispatch(setSubType(NOT_SELECTED_STR));
        dispatch(setMaterial(NOT_SELECTED_STR));
        payload === MISSING_TOOTH && dispatch(setCurrentContouring(null));
        subTypeRef.current.focus();
        break;
      case 'subType':
        dispatch(setSubType(payload));
        materialRef.current.focus();
        break;
      case 'material':
        dispatch(setMaterial(payload));
        break;
      default:
        break;
    }
  };
  const findingsOfCampaign = findingsList(config, campaign);

  return (
    <>
      {!isToothLevel && (
        <Labeling
          disabled={labelingDisabled}
          detailsRef={detailsRef}
          findings={findingsId}
          details={detailsId}
          probability={probability}
          onChangedOutput={changeDropDownSelectedValue}
          findingsList={findingsOfCampaign}
        />
      )}
      {isToothLevel && (
        <ToothLabeling
          subTypeRef={subTypeRef}
          materialRef={materialRef}
          type={type}
          subType={subType}
          material={material}
          onChangedOutput={changeDropDownSelectedValue}
        />
      )}
    </>
  );
};

export default MarkLabelingContainer;
