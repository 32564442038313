export const SubTypes = {
  'Not Selected': 'Not Selected',
  'Crown on Tooth': 'Crown on Tooth',
  'Implant: Adhesive': 'Implant: Adhesive',
  'Implant: Screw Retained': 'Implant: Screw Retained',
  'Tooth Abutment - Crown': 'Tooth Abutment - Crown',
  'Tooth Abutment - Inlay/Onlay': 'Tooth Abutment - Inlay/Onlay',
  'Implant Abutment': 'Implant Abutment',
  Pontic: 'Pontic',
  'Maryland Abutment': 'Maryland Abutment',
  'Screw Retained': 'Screw Retained',
  'Prefacricated Abutment': 'Prefacricated Abutment',
  'Custom Abutment': 'Custom Abutment',
  'Ectopic Tooth': 'Ectopic Tooth',
  'Over Erupted': 'Over Erupted',
  'Partially Erupted': 'Partially Erupted',
  'Impacted Tooth': 'Impacted Tooth',
  'Rotated Tooth': 'Rotated Tooth',
  'Profound Coronal Destruction': 'Profound Coronal Destruction',
  'Roots Residues': 'Roots Residues',
  M: 'M',
  D: 'D',
  'Aligner Attachment': 'Aligner Attachment',
  'Metal Bracket': 'Metal Bracket',
  'Plastic Bracket': 'Plastic Bracket',
  'Lingual Retainer': 'Lingual Retainer',
  'Ortho Button/Ring': 'Ortho Button/Ring',
  Other: 'Other',
};
