import React, { useState, useEffect, useMemo } from 'react';
import styles from '../ImageFrameManipulation.module.css';
import { cloneDeep } from 'lodash';
import ToothTierTwoConflictMenu from './ToothTierTwoConflictMenu';
import { KeyCodes } from '../../../../shared-logic/enums';
//redux
import { useSelector } from 'react-redux';
import { prevTierStateSelector } from '../../../../redux/taskStateImages/outputSlice';
import { currentToothIndexSelector } from '../../../../redux/marks/currentMarkingSlice';
//custom hooks
import { usePreviousValue } from '../../customHooks';
//logic
import {
  prepareTaggingData,
  getClusterText,
} from './ToothTierTwoConflictMenu.logic';
import { isKeyCode } from '../../../../shared-logic/keyFunctions';

const ToothTierTwoConflictMenuContainer = () => {
  const [menuIsActive, setMenuIsActive] = useState(false);
  const prevTierState = useSelector(prevTierStateSelector);
  const currentToothIndex = useSelector(currentToothIndexSelector);

  const currentTooth = useMemo(
    () => prevTierState.filter(({ id }) => id === currentToothIndex),
    [prevTierState, currentToothIndex]
  );

  useEffect(() => {
    createEventListener('keydown', setMenuIsActive.bind(null, true));
    createEventListener('keyup', setMenuIsActive.bind(null, false));
    return () => {
      removeEventListener('keydown', setMenuIsActive.bind(null, true));
      removeEventListener('keyup', setMenuIsActive.bind(null, false));
    };
  }, []);

  const prevCurrentToothState = usePreviousValue(cloneDeep(currentTooth));

  useEffect(() => {
    if (
      JSON.stringify(prevCurrentToothState) === JSON.stringify(currentTooth)
    ) {
      return;
    }
    const conflictedIds = currentTooth
      .map((tooth) => (tooth.consistent === false ? tooth.id : null))
      .filter((id) => id);
    if (conflictedIds.includes(currentToothIndex)) {
      setMenuIsActive(true);
    } else {
      setMenuIsActive(false);
    }
  }, [currentToothIndex, currentTooth, prevCurrentToothState]);

  const renderTaggingData = (data) => {
    const preparedData = prepareTaggingData(data);
    return getClusterText(preparedData).map((item, i) =>
      item.bold ? (
        <div key={i} className={styles.innerTag}>
          <b>{item.text}</b>
        </div>
      ) : (
        <div key={i} className={styles.innerTag}>
          {item.text}
        </div>
      )
    );
  };

  const createEventListener = (eventName, callback) => {
    document.addEventListener(eventName, (e) => {
      if (isKeyCode(e, KeyCodes.T_KEY)) {
        callback();
      }
    });
  };

  const removeEventListener = (eventName, callback) => {
    document.removeEventListener(eventName, (e) => {
      if (isKeyCode(e, KeyCodes.T_KEY)) {
        callback();
      }
    });
  };

  const isEmpty = (obj) => {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) return false;
    }
    return true;
  };

  const removeMarkingCategory = (tooth, category) => {
    const { [category]: _, ...rest } = tooth.markings;
    return { ...tooth, markings: rest };
  };

  const conflictedTeeth = currentTooth
    .filter((tooth) => !isEmpty(tooth.markings.inconsistent))
    .map((tooth) => removeMarkingCategory(tooth, 'consistent'));

  const nonConflictedTeeth = currentTooth
    .filter((tooth) => !isEmpty(tooth.markings.consistent))
    .map((tooth) => removeMarkingCategory(tooth, 'inconsistent'));

  const renderTeeth = (teethArray) =>
    teethArray.map((tooth, i) => (
      <div key={i}>{renderTaggingData(tooth.markings)}</div>
    ));

  const menuClassName = menuIsActive
    ? `${styles.tierTwoMenuActive} ${styles.tierTwoTagsContainer}`
    : styles.tierTwoTagsContainer;

  return (
    <ToothTierTwoConflictMenu
      conflictedTeeth={renderTeeth(conflictedTeeth)}
      nonConflictedTeeth={renderTeeth(nonConflictedTeeth)}
      menuClassName={menuClassName}
    />
  );
};

export default ToothTierTwoConflictMenuContainer;
